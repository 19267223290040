<!--  -->
<template>
  <el-container class="h100">
    <el-header>
      <Header></Header>
    </el-header>
    <el-container class="main">
      <el-aside>
       <Aside></Aside>
      </el-aside>
      <el-main>
        <keep-alive >
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>

      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from '@/components/Header';
import Aside from '@/components/Aside';

export default {
  data () {
    return {

    }
  },

  components: {
    Header,
    Aside
  },

  mounted () {
  },
}

</script>
<style lang='less' scoped>
  .main {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);;
  }
  .el-header{
    color: #333;
    text-align: center;
    line-height: 60px;
    padding: 0;
  }

  .el-aside {
    color: #333;
    text-align: center;
    overflow-x: hidden;
    width: 200px !important;
    background: #fff;
    height: 100%;
  }
  .el-main {
    background-color: #fff;
    color: #333;
    text-align: left;
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 20px;
    width: 100%;
    border-radius: 10px;
  }

</style>
