<template>
  <div class="aside">
    <el-aside
      style="overflow: hidden; background: #292c33; transition: width 0.3s;"
      width="200"
    >
      <div>
        <el-menu
          class="menu"
          :default-active="defaultIndex"
          :default-openeds="defaultOpeneds"
          :collapse="isCollapse"
          background-color="#fff"
          text-color="#333"
          active-text-color="#409eff"
        >
          <div v-for="(submenu, index) in menus" :key="submenu.name">
            <!-- 有二级菜单 -->
            <el-submenu :index="submenu.meta.index" v-if="submenu.children && submenu.isMenu" >
              <template slot="title">
                <i class="icon" :class="submenu.meta.icon"></i>
                <span class="side-menu-title">{{submenu.meta.title}}</span>
              </template>
              <el-menu-item
                v-for="menuItem in submenu.children"
                :key="menuItem.meta.index"
                :index="menuItem.meta.index"
                v-if="menuItem.isMenu"
                class="side-menu"
                @click="skipRouter(menuItem)"
              >
                {{menuItem.meta.title}}
              </el-menu-item>
            </el-submenu>
          </div>
        </el-menu>
      </div>
    </el-aside>
  </div>
</template>

<script>

import { getUserRouter } from "@/api/jurisdiction";
import { getUserInfo } from '@/api/login';
import mainRoutes  from '@/router/main'

export default {
  data() {
    return {
      menus: [],
      defaultIndex: this.$route.meta.index,
      defaultOpeneds: [],
      isCollapse: false,
      limitMenu: mainRoutes[0].children,
      userInfo: {},
    }
  },

  mounted() {
    this.getUserInfo();
  },
  computed: {
    handleData() {
      return {
        menu: this.menus,
        route: this.$route
      }
    }
  },
  watch: {
    handleData: {
      handler(info,old) {
        if (!info || !info.menu.length) {
          return
        }
        this.computeActive(info.route)
      },
      deep: true,
    }
  },
  methods: {
    skipRouter(row) {
      this.$router.push(row.path)
    },
    computeActive(val) {
      if (!val) return
      this.defaultIndex = ''; // 选择的索引
      this.menus.forEach(sub => {
        const menu = sub.children.find(menu => menu.name === val.name); // 找到当前的路由
        if (!menu) return;
        // 如果不是菜单路由 就展开当前所有的
        if (!menu.isMenu) {
          this.defaultOpeneds = [sub.meta.index];
        } else {
          // 如果是菜单路由 就展开
          this.defaultIndex = menu.meta.index;
        }
      })
    },
    handleMenu(ldata) {
      const defaultSortIndex = 1000
      /** 权限处理 */
      this.limitMenu.forEach(nowMe => {
        /*判断是否有当前一级菜单权限*/
        const flag = ldata.some(el => el.path.includes(nowMe.name) )
        if(flag) {
          nowMe.isMenu = true
          /** 找到当前一级菜单 */
          const mainFirstMenu = ldata.find(el => el.path.includes(nowMe.name))
          //排序序号获取
          nowMe.sort = mainFirstMenu.sort || defaultSortIndex
          //名称获取
          nowMe.meta.title = mainFirstMenu.name || nowMe.meta.title
          /** 遍历当前一级菜单 判断二级菜单权限 */
          nowMe.children.forEach(nowMeChild => {
            nowMeChild.isMenu = false
            mainFirstMenu.subMenuList.forEach(mainFirstMenuChild => {
              if (nowMeChild.path == mainFirstMenuChild.path) {
                //权限是否显示
                nowMeChild.isMenu = true
                //后台获取的排序序号
                nowMeChild.sort = mainFirstMenuChild.sort || defaultSortIndex
                //名称
                nowMeChild.meta.title = mainFirstMenuChild.name || nowMeChild.meta.title
              }
            })
          })
          //对子目录进行排序
          nowMe.children.sort((a,b) => {
            if(!b.sort) {
              return -1
            }
            if(!a.sort) {
              return 1
            }
            return a.sort - b.sort
          })
        }
        if(!flag) {
          nowMe.isMenu  = false
        }
        if (nowMe.name == 'jurisdiction' && this.userInfo.role == 2) {
          //排序序号获取
          nowMe.isMenu = true
          nowMe.children.forEach(el3 => {
            if (this.userInfo.superAdmin == 1 && ( el3.name == 'service' || el3.name == 'menu' )) {
              el3.isMenu = true
            }
          })
        }
      });
      this.limitMenu.sort((a,b) => {
        if(!b.sort) {
          return -1
        }
        if(!a.sort) {
          return 1
        }
        return a.sort - b.sort
      })
      const firstMenu = this.limitMenu.find(el => el.isMenu == true)
      const secMenu = firstMenu.children.find(el => el.isMenu == true)
      localStorage.setItem('menu', JSON.stringify(this.limitMenu))
      this.menus = this.limitMenu;
    },
    async getUserRouter() {
      const res = await getUserRouter({})
      const allPathArr = res.ldata.map(el => el.subMenuList)
      const allPathArrFlat = allPathArr.flat()
      if (!allPathArrFlat.length) {
        this.$message.error('暂无菜单权限，请配置权限')
        this.$router.push({name: 'login'})
        return
      }
      this.handleMenu(res.ldata)
    },
     async getUserInfo() {
       try {
          const res = await getUserInfo();
          this.userInfo = res.mdata
          this.$store.commit('user/updateUserInfo', this.userInfo)
          localStorage.setItem('user', JSON.stringify(res.mdata))
          this.getUserRouter()
       } catch (error) {
         console.log(error);
       }
    },
  },
}
</script>
<style scoped lang="less">
.aside {
  display: flex;
  flex-direction: column;
  .menu {
    flex: 1;
    overflow: auto;
  }
  .router-link-active {
    color: #fff;
  }
  .router {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
  &/deep/.el-submenu {
    overflow: hidden;
    width: 100%;
  }
  &/deep/.el-menu-item {
    color: rgb(158, 158, 158);
    height: 45px;
    line-height: 45px;
    overflow: hidden;
  }
  &/deep/.el-menu {
    border:0;
  }
  &/deep/.el-submenu__title {
    height: 45px;
    line-height: 45px;
    color: rgb(158, 158, 158);
    text-align: left;
  }
  &/deep/.el-menu-item-group__title {
    color: rgb(158, 158, 158);
  }
  &/deep/.el-icon-message {
    color: #b7b8b8;
    font-size: 14px;
  }
  .icon {
    font-size: 16px;
    margin-right: 6px;
  }

}

.el-menu-item,
.el-submenu__title {
  //悬浮时 c3c2c217  ffffff17
  &:hover {
    color: #fff !important;
    background-color: rgb(64, 158, 255) !important;
  }
}
</style>
