<template>
   <div class="header w100">
     <div  class="logo fl">
       电池租赁运营管理系统
     </div>
    <div class="fr">
      <el-dropdown class="curp" >
        <span class="c2">当前登录：{{userInfo.phone}} <i class="el-icon-arrow-down el-icon--right c2" style="margin-right: 15px"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="loginOut" >退出登录</el-dropdown-item>
          <el-dropdown-item @click.native ='goChangebattery' v-if="userInfo.role == 2 || userInfo.swapMangeAuthority == 1">跳转到换电</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getUserInfo, loginOut } from '@/api/login';
import mainRoutes  from '@/router/main'

export default {
  data() {
    return {
      userInfo: {},
    }
  },
  created () {
    this.getUserInfo();
  },
  methods: {
    async loginOut() {
      await loginOut()
      localStorage.removeItem('user')
      this.$router.push('/');
    },
    async getUserInfo() {
       try {
          const res = await getUserInfo();
          this.userInfo = res.mdata
          this.$store.commit('user/updateUserInfo', this.userInfo)
          localStorage.setItem('user', JSON.stringify(res.mdata))
       } catch (error) {
         console.log(error);
       }
    },
    goChangebattery() {
      const {origin} = location
      window.location.href =  `${origin}/managePage/batterySwapMgmt`
    }
  },
  components: {

  }
}
</script>

<style scoped lang="less">
  .header {
    background: #292c33;
    border-bottom: 1px solid #c2cfd6;
    box-shadow: 0 2px 6px 0 rgba(136, 156, 176, 0.2);
    height: 56px;
    font-size: 14px;
    color: #20a8d8;
  }
  .c2 {
    color: #20a8d8;
  }
  .logo {
    height: 100%;
    font-size: 22px;
    color: #fff;
    padding-left: 20px;
  }
</style>
